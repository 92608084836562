.underline-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.slide-left {
  animation: 1s slide-left;
}
@keyframes slide-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}

.slide-right {
  animation: 1s slide-right;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}
