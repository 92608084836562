@keyframes slide-left-detail {
  0% {
    transform: translateX(100%); /* End at the original position */
  }
  100% {
    transform: translateX(0); /* Start off-screen */
  }
}

.slide-right-detail {
  animation: 1s slide-right-detail;
}
@keyframes slide-right-detail {
  0% {
    transform: translateX(0); /* End at the original position */
  }
  100% {
    transform: translateX(100%); /* Start off-screen */
  }
}
