div.statement-modal span.ant-input-affix-wrapper {
  border-radius: 15px !important;
  height: 45px;
}
.ant-modal .ant-btn {
  font-size: 20px;
}

.ant-modal .ant-btn:hover {
  background-color: #6f72ac;
}

.medical-statement-modal ::-webkit-scrollbar {
  display: none;
  width: 4px;
}

.medical-statement-modal ::-webkit-scrollbar-track {
  border-radius: 99px;
  background-color: transparent;
}

.medical-statement-modal ::-webkit-scrollbar-thumb {
  height: 2px;
  cursor: pointer;
  border-radius: 99px;
  background-color: white;
}
.medical-statement-modal ::selection {
  background: #ffffff !important;
  color: black;
}
